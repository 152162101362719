<template>
  <div>
    <send-email-modal
      :show-modal="emailDetails.emailModalOpen"
      :to="emailDetails.email"
      :ok="(data) => sendStudentMail(data)"
      :close="closeEmailModal"
    />

    <send-email-modal
      :show-modal="bulkEmailDetails.emailModalOpen"
      :to="bulkEmailDetails.email"
      :ok="(data) => sendBulkMail(data)"
      :close="closeEmailModal"
    />

    <b-modal
      :no-close-on-backdrop="true"
      v-model="showSignatureModal"
      centered
      hide-footer
      title="Add Signature"
    >
      <quill-editor
        id="quil-content"
        :options="editorOption"
        v-model="email_signature"
      />
      <div class="d-flex justify-content-end">
        <b-button @click="saveLMSUserDetails" variant="primary" class="mt-1"
          >Save</b-button
        >
      </div>
    </b-modal>

    <!-- Create Student Confirmation Modal -->
    <b-modal
      :no-close-on-backdrop="true"
      v-model="showCreateStudentModal"
      @hide="resetCreateStudentModal"
      centered
      hide-footer
      title="Create Student"
    >
      <validation-observer ref="addStudentForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group label="First name" label-for="register-firstname">
              <validation-provider
                #default="{ errors }"
                name="Firstname"
                rules="required"
              >
                <b-form-input
                  id="register-firstname"
                  name="register-firstname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="First name"
                  v-model="student_form.firstname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last name" label-for="register-lastname">
              <validation-provider
                #default="{ errors }"
                name="Lastname"
                rules="required"
              >
                <b-form-input
                  id="register-lastname"
                  name="register-lastname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Last Name"
                  v-model="student_form.lastname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Phone number" label-for="register-mobile">
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                rules="required"
              >
                <b-form-input
                  id="register-mobile"
                  type="number"
                  name="register-mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="+91XXXXXXXXXX"
                  v-model="student_form.mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Email" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                rules="required"
              >
                <b-form-input
                  id="register-email"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  v-model="student_form.email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="register-password" label="Password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="register-password"
                    class="form-control-merge rp_padding"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    :type="passwordFieldType"
                    placeholder="············"
                    v-model="student_form.password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button
              @click="showCreateStudentModal = false"
              variant="outline-danger mr-2"
            >
              Close
            </b-button>
            <b-button
              variant="outline-primary"
              type="submit"
              @click.prevent="createStudent"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Modal End -->

    <!-- Follow Up Modal -->
    <b-modal
      :no-close-on-backdrop="true"
      size="lg"
      id="follow-up-modal"
      centered
      ref="follow-up-modal"
      title="Change Lead Stage"
      hide-footer
      @hidden="resetFollowUpModal"
    >
      <b-form @submit.prevent="addFollowUp">
        <b-row style="gap: 10px 0" class="m-2">
          <b-col cols="12">
            <p
              v-if="selected_lead && selected_lead.stage"
              class="font-weight-bold font-small-2"
            >
              Current Stage:
              <span :style="`color: ${selected_lead.stage.color}`">{{
                selected_lead.stage.name
              }}</span>
            </p>
            <p v-else class="font-weight-bold font-small-1">
              This lead is untouched
            </p>
            <v-select
              v-model="follow_up_details.stage_id"
              placeholder="Stage"
              clearable
              :options="stages"
              label="name"
              :reduce="(item) => item.s_id"
              @input="
                () => {
                  follow_up_details.sub_stage_id = null;
                }
              "
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!follow_up_details.stage_id"
                  v-bind="attributes"
                  v-on="events"
                /> </template
            ></v-select>
          </b-col>
          <b-col cols="12">
            <p
              v-if="selected_lead && selected_lead.sub_stage"
              class="font-weight-bold font-small-2"
            >
              Current Sub Stage: {{ selected_lead.sub_stage.name }}
            </p>
            <v-select
              :disabled="follow_up_details.stage_id ? false : true"
              v-model="follow_up_details.sub_stage_id"
              placeholder="Sub Stage"
              clearable
              :options="
                sub_stages.filter(
                  (ss) => ss.stage_id === follow_up_details.stage_id
                )
              "
              label="name"
              :reduce="(item) => item.ss_id"
            />
          </b-col>
          <b-col cols="12">
            <!-- <div class="mic-container" @click="ToggleMic">
              <div class="circle" :class="{ active: recording }">
                <div class="mic-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    width="14"
                    height="22"
                    fill="#b2b1b1"
                  >
                    <path
                      d="M192 0C139 0 96 43 96 96l0 160c0 53 43 96 96 96s96-43 96-96l0-160c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 89.1 66.2 162.7 152 174.4l0 33.6-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l72 0 72 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-33.6c85.8-11.7 152-85.3 152-174.4l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-40z"
                    />
                  </svg>
                </div>
              </div>
            </div> -->
            <!-- <audio class="playback" controls></audio> -->

            <b-form-textarea
              class=""
              no-resize
              v-model="follow_up_details.fu_remark"
              placeholder="Add A Remark"
              rows="3"
            />
          </b-col>
          <b-col cols="8">
            <flat-pickr
              :disabled="!isFollowUpDateRequired()"
              :required="isFollowUpDateRequired()"
              placeholder="Follow Up Date"
              v-model="follow_up_details.date"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                allowInput: true,
              }"
            />
          </b-col>
          <b-col cols="4">
            <b-form-input
              :disabled="!isFollowUpDateRequired()"
              :required="isFollowUpDateRequired()"
              placeholder="Follow Up Time"
              v-model="follow_up_details.time"
              type="time"
            />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end mt-1">
            <b-button variant="danger" class="mr-1" @click="hideFollowUpModal"
              >Cancel</b-button
            >
            <b-button variant="primary" type="submit">Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- Modal End -->

    <!-- Modify Table Modal -->
    <b-modal
      :no-close-on-backdrop="true"
      size="md"
      id="modify-table-modal"
      centered
      ref="modify-table-modal"
      title="Modify Table"
      hide-footer
    >
      <div>
        <div class="mb-1 d-flex justify-content-end" style="gap: 10px">
          <b-badge
            pill
            variant="primary"
            class="cursor-pointer"
            @click="selectAllTableColumns"
            >Select All</b-badge
          >
          <b-badge
            pill
            variant="danger"
            class="cursor-pointer"
            @click="resetTableColumns"
            >Reset Columns</b-badge
          >
        </div>

        <div
          style="
            height: 600px;
            overflow: hidden;
            overflow-y: auto;
            background-color: #fafafa;
          "
          class="border rounded p-1"
        >
          <draggable
            v-model="tableColumns"
            group="columns"
            @start="drag = true"
            @end="saveTableColumns"
          >
            <transition-group>
              <b-card
                class="shadow-sm border"
                style="margin-bottom: 5px"
                no-body
                v-for="column in tableColumns"
                :key="'column_' + column.key"
              >
                <div class="p-1 d-flex align-items-center cursor-pointer">
                  <b-form-checkbox
                    class="mr-1"
                    v-model="column.show"
                    @change="saveTableColumns"
                  />
                  <h5 class="my-0 py-0">{{ column.label }}</h5>
                </div>
              </b-card>
            </transition-group>
          </draggable>
        </div>
      </div>
    </b-modal>
    <!-- Modal End -->

    <div v-if="user_type == 'agent'">
      <b-card
        no-body
        style="height: 150px"
        class="d-flex justify-content-center align-items-center"
      >
        <h3>"Previledge access only to Aliff Franchise."</h3>
      </b-card>
    </div>

    <b-card v-if="user_type !== 'agent' && accessToken">
      <!-- Filters -->
      <b-card v-if="showFilters" class="mb-0">
        <div class="d-flex justify-content-between mb-1">
          <h3>Filters</h3>
          <div class="d-flex" style="gap: 10px">
            <div
              style="height: 30px"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                cursor-pointer
                px-2
                rounded-sm
                shadow-sm
                bg-primary
                text-white
              "
              @click="resetFilters"
            >
              Reset Filters
            </div>
            <div
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                rounded-pill
                cursor-pointer
                shadow-sm
                bg-danger
              "
              style="width: 30px; height: 30px; margin-right: 5px"
              @click="showFilters = !showFilters"
            >
              <feather-icon icon="XIcon" style="stroke: #fff" />
            </div>
          </div>
        </div>

        <b-row style="gap: 10px 0">
          <!-- Search -->
          <b-col cols="12" class="">
            <b-form-input
              v-model="filters.search"
              placeholder="Search"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Cities -->
          <b-col cols="3">
            <v-select
              v-model="filters.city_id"
              placeholder="City"
              clearable
              :options="cities"
              label="name"
              :reduce="(item) => item.city_id"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Stage -->
          <b-col cols="3">
            <v-select
              v-model="filters.s_id"
              placeholder="Stage"
              clearable
              :options="getLeadStagesWithUntouched()"
              label="name"
              :reduce="(item) => item.s_id"
              @input="
                () => {
                  filters.ss_id = null;
                  onFilterChange();
                }
              "
            />
          </b-col>

          <!-- Sub States -->
          <b-col cols="3" v-if="filters.s_id">
            <v-select
              v-model="filters.ss_id"
              placeholder="Sub Stage"
              clearable
              :options="sub_stages.filter((ss) => ss.stage_id === filters.s_id)"
              label="name"
              :reduce="(item) => item.ss_id"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Lead Source -->
          <b-col cols="3">
            <v-select
              v-model="filters.source_id"
              placeholder="Source"
              clearable
              :options="sources"
              label="name"
              :reduce="(item) => item.ls_id"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Lead Medium -->
          <b-col cols="3">
            <v-select
              v-model="filters.m_id"
              placeholder="Medium"
              clearable
              :options="mediums"
              label="name"
              :reduce="(item) => item.m_id"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Lead Campaign -->
          <b-col cols="3">
            <v-select
              v-model="filters.c_id"
              placeholder="Campaign"
              clearable
              :options="campaigns"
              label="name"
              :reduce="(item) => item.c_id"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Lead Category -->
          <b-col cols="3">
            <v-select
              v-model="filters.category_id"
              placeholder="Category"
              clearable
              :options="categories"
              label="name"
              :reduce="(item) => item.lc_id"
              @input="
                () => {
                  onCategoryChange();

                  onFilterChange();
                }
              "
            />
          </b-col>

          <!-- Registration Date -->
          <b-col cols="3">
            <flat-pickr
              placeholder="Registration Date Range"
              v-model="filters.registration_date"
              class="form-control"
              :config="{
                dateFormat: 'd-m-Y',
                allowInput: true,
                mode: 'range',
              }"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Follow Up Date -->
          <b-col cols="3">
            <flat-pickr
              placeholder="Follow Up Date Range"
              v-model="filters.follow_up_date"
              class="form-control"
              :config="{
                dateFormat: 'd-m-Y',
                allowInput: true,
                mode: 'range',
              }"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Activity Date -->
          <b-col cols="3">
            <flat-pickr
              placeholder="Activity Date Range"
              v-model="filters.activity_date"
              class="form-control"
              :config="{
                dateFormat: 'd-m-Y',
                allowInput: true,
                mode: 'range',
              }"
              @input="onFilterChange"
            />
          </b-col>

          <!-- Member Filter -->
          <b-col cols="3" v-if="isTeamLeader">
            <v-select
              clearable
              placeholder="Lead Owner"
              v-model="filters.member_id"
              :options="team_members"
              label="user_first_name"
              :reduce="(item) => item.user_id"
              @input="onFilterChange"
            >
              <template slot="option" slot-scope="option">
                {{ option.user_first_name }} {{ option.user_last_name }}
              </template>

              <template slot="selected-option" slot-scope="option">
                {{ option.user_first_name }} {{ option.user_last_name }}
              </template>
            </v-select>
          </b-col>

          <b-col
            cols="3"
            v-for="cfv in lc_field_values"
            :key="'cfv_' + cfv.lcf_id"
          >
            <v-select
              v-model="category_wise_filters[cfv.lcf_id]"
              :placeholder="cfv.name"
              clearable
              :options="cfv.values"
              @input="onFilterChange"
            />
          </b-col>

          <!-- <b-col cols="12" class="d-flex justify-content-end" style="gap: 10px">
            <b-button variant="primary" class="text-nowrap" @click="resetFilters">Reset</b-button>
            <b-button
              variant="primary"
              class="text-nowrap"
              @click="applyFilters"
              >Apply</b-button
            >
          </b-col> -->
        </b-row>
      </b-card>

      <!-- Table -->
      <b-card
        style="overflow-y: scroll; scrollbar-width: none"
        id="university-list"
        no-body
      >
        <div v-if="show_bulk_actions" class="w-100">
          <div
            class="
              border-top border-bottom
              d-flex
              align-items-center
              justify-content-center
              py-1
              mb-1
            "
          >
            <b-button
              variant="primary"
              style="width: fit-content"
              @click="showBulkEmailModal"
            >
              Send Bulk Mail
            </b-button>
          </div>
        </div>
        <b-col
          cols="12"
          class="
            d-flex
            align-items-end
            justify-content-between justify-content-sm-between
            mb-1
          "
        >
          <div>Total {{ pagination.items_count }} leads</div>
          <!-- <div>
            <label>Per Page</label>
            <v-select v-model="pagination.perPage" :options="pagination.perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block w-100" @input="onChange" />
          </div> -->
          <div class="d-flex" style="gap: 5px">
            <div
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                rounded-pill
                cursor-pointer
                shadow-sm
                bg-primary
              "
              style="width: 30px; height: 30px; margin-right: 5px"
              @click="showSignatureModal = true"
            >
              <feather-icon icon="PenToolIcon" style="stroke: #fff" />
            </div>
            <div
              v-if="!showFilters"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                rounded-pill
                cursor-pointer
                shadow-sm
                bg-primary
              "
              style="width: 30px; height: 30px; margin-right: 5px"
              @click="showFilters = true"
            >
              <feather-icon icon="FilterIcon" style="stroke: #fff" />
            </div>
            <div
              style="height: 30px"
              class="
                border
                d-flex
                justify-content-center
                align-items-center
                cursor-pointer
                px-2
                rounded-sm
                shadow-sm
                bg-primary
                text-white
              "
              @click="addNewLead"
            >
              Create Lead
            </div>
          </div>
        </b-col>

        <!-- v-infinite-scroll="onScroll"
          :infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" -->

        <b-table
          style="max-height: 60vh"
          :fields="tableColumns.filter((c) => c.show)"
          :items="allLeads"
          responsive
          sticky-header
          show-empty
          empty-text="No Leads Available"
          :busy="isLoading"
        >
          <!-- Set Minimum Width of Each Column -->
          <template v-slot:head()="data">
            <span class="cursor-pointer" @click="showModifyTableModal">{{
              data.label
            }}</span>
          </template>

          <template #table-colgroup="scope">
            {{ scope }}
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ minWidth: '200px' }"
            />
          </template>

          <!-- Loader -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #head(name)="data">
            <div class="d-flex align-items-center" style="gap: 20px">
              <div style="width: 20px">
                <b-form-checkbox
                  v-model="select_all"
                  @change="selectOrDeselectAll"
                />
              </div>
              NAME
            </div>
          </template>

          <!-- Name -->
          <template #cell(name)="data">
            <div
              style="max-width: 275px; gap: 20px"
              class="d-flex align-items-center justify-content-start"
            >
              <div style="width: 20px">
                <b-form-checkbox
                  v-model="data.item.is_selected"
                  @change="() => selectOrDeselectSingle(data.item)"
                />
              </div>

              <div class="d-flex justify-content-between w-100">
                <div>
                  <div
                    style="width: max-content; margin-bottom: 5px"
                    @click="
                      !data.item.stage ||
                        (data.item.stage &&
                          !data.item.stage.is_final &&
                          showFollowUpModal(data.item))
                    "
                  >
                    <b-badge
                      class="cursor-pointer"
                      pill
                      v-if="data.item.stage && data.item.stage.name"
                      :style="`background-color: ${data.item.stage.color}`"
                      >{{ data.item.stage.name }}</b-badge
                    >

                    <b-badge
                      class="cursor-pointer"
                      @click="showFollowUpModal(data.item)"
                      pill
                      v-else
                      >Untouched</b-badge
                    >
                  </div>
                  <p class="text-nowrap">
                    {{ data.item.full_name }}
                    <b-badge
                      class="cursor-pointer bg-danger"
                      v-if="data.item.repeat_count > 1"
                      >{{ data.item.repeat_count }}</b-badge
                    >
                  </p>
                </div>
                <div class="d-flex justify-content-center">
                  <b-dropdown variant="outline-none" dropright no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="15" />
                    </template>
                    <b-dropdown-item
                      @click="showFollowUpModal(data.item)"
                      v-if="
                        !data.item.stage ||
                        (data.item.stage &&
                          !data.item.stage.is_final &&
                          data.item.user &&
                          data.item.user.user_email === user_email)
                      "
                    >
                      <div class="d-flex align-items-center" style="gap: 10px">
                        <feather-icon icon="ClockIcon" />
                        Change Lead Stage
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :to="{
                        name: 'Lead Details',
                        params: { lead_id: data.item.lead_id },
                      }"
                    >
                      <div class="d-flex align-items-center" style="gap: 10px">
                        <feather-icon icon="EyeIcon" /> View Details
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="data.item.email">
                      <div
                        class="d-flex align-items-center"
                        style="gap: 10px"
                        @click="() => showEmailModal(data.item.email)"
                      >
                        <feather-icon icon="MailIcon" /> Send Mail
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="
                        data.item.user &&
                        data.item.user.user_email === user_email
                      "
                      :to="{
                        name: 'Lead Form',
                        query: { lead_id: data.item.lead_id },
                      }"
                    >
                      <div class="d-flex align-items-center" style="gap: 10px">
                        <feather-icon icon="EditIcon" /> Edit Lead
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="onOpenCreateStudentModal(data.item)"
                      v-if="
                        data.item.stage && data.item.stage.name === 'Completed'
                      "
                    >
                      <div class="d-flex align-items-center" style="gap: 10px">
                        <feather-icon icon="UserPlusIcon" /> Create Account
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </template>

          <!-- Email -->
          <template #cell(email)="data">
            <div style="width: max-content">
              <a>
                {{ data.item.email }}
              </a>
            </div>
          </template>

          <template #cell(mobile)="data">
            <div style="width: max-content" v-if="data.item.mobile">
              {{ data.item.mobile }}
              <a
                target="_blank"
                :href="`https://wa.me/${getWhatsappFormattedMobile(
                  data.item.mobile
                )}`"
              >
                <img
                  src="/whatsapp.webp"
                  alt=""
                  style="width: 25px; height: 25px"
                />
              </a>
            </div>
          </template>

          <!-- City -->
          <template #cell(city)="data">
            <div style="width: max-content">
              {{ data.item.city ? data.item.city.name : "" }}
            </div>
          </template>

          <!-- Source -->
          <template #cell(source)="data">
            <div style="width: max-content">
              {{ data.item.source ? data.item.source.name : null }}
            </div>
          </template>

          <!-- Medium -->
          <template #cell(medium)="data">
            <div style="width: max-content">
              {{ data.item.medium ? data.item.medium.name : null }}
            </div>
          </template>

          <!-- Campaign -->
          <template #cell(campaign)="data">
            <div style="width: max-content">
              {{ data.item.campaign ? data.item.campaign.name : null }}
            </div>
          </template>

          <!-- Registration Date -->
          <template #cell(registration_date)="data">
            <div style="width: max-content">
              {{ moment(data.item.created_at).format("DD MMM, YYYY") }}
            </div>
          </template>

          <!-- Sub Stage -->
          <template #cell(sub_stage)="data">
            <div
              style="width: max-content"
              class="cursor-pointer"
              @click="
                !data.item.stage ||
                  (data.item.stage &&
                    !data.item.stage.is_final &&
                    showFollowUpModal(data.item))
              "
            >
              {{ data.item.sub_stage ? data.item.sub_stage.name : "" }}
            </div>
          </template>

          <!-- Follow Up Date -->
          <template #cell(follow_up_date)="data">
            <div style="width: max-content">
              <b-badge v-if="data.item.follow_up_date" pill variant="warning">
                {{ moment(data.item.follow_up_date).format("DD MMM, YYYY") }}
                at
                {{ moment(data.item.follow_up_date).format("hh:mm A") }}
              </b-badge>
            </div>
          </template>

          <!-- Category -->
          <template #cell(category)="data">
            <div style="width: max-content">
              {{ data.item.category ? data.item.category.name : "" }}
            </div>
          </template>

          <template #cell(follow_up_remark)="data">
            <div style="width: 450px" :title="data.item.remark">
              {{
                data.item.follow_ups[0]
                  ? data.item.follow_ups[0].remark
                  : "None"
              }}
            </div>
          </template>

          <!-- Remark -->
          <!--  v-b-tooltip.hover  -->
          <template #cell(remark)="data">
            <div
              style="width: 150px"
              class="text-truncate"
              :title="data.item.remark"
            >
              {{ data.item.remark || "None" }}
            </div>
          </template>

          <!-- Assignee -->
          <template #cell(assignee)="data">
            <div style="width: 250px">
              <v-select
                clearable
                v-model="data.item.user_id"
                :options="team_members"
                label="user_first_name"
                @input="() => changeAssignee(data.item)"
                :reduce="(item) => item.user_id"
              >
                <template slot="option" slot-scope="option">
                  {{ option.user_first_name }} {{ option.user_last_name }}
                </template>

                <template slot="selected-option" slot-scope="option">
                  {{ option.user_first_name }} {{ option.user_last_name }}
                </template>
              </v-select>
            </div>
          </template>

          <!-- Dynamic Fields -->
          <template
            v-slot:[`cell(${field.lcf_id})`]="data"
            v-for="field in category_fields"
          >
            <div style="width: max-content" :key="'field_' + field.lcf_id">
              {{
                data.item.field_values.find(
                  (lcfv) => lcfv.lcf_id === field.lcf_id
                )
                  ? data.item.field_values.find(
                      (lcfv) => lcfv.lcf_id === field.lcf_id
                    ).value
                  : "-"
              }}
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <!-- <span class="text-muted"> {{ pagination.items_count }} entries </span> -->
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                aria-controls="StudentsListTableId"
                v-model="pagination.currentPage"
                :total-rows="pagination.items_count"
                :per-page="pagination.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="onChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card>

    <div v-if="user_type !== 'agent' && accessTokenFailed">
      <b-card
        no-body
        style="height: 150px"
        class="d-flex justify-content-center align-items-center"
      >
        <h3>"Please Contact Adminstrator For Activating Your Leads."</h3>
      </b-card>
    </div>
  </div>
</template>

<script>
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import CommonServices from "@/apiServices/CommonServices";
import CounselorServices from "@/apiServices/CounselorServices";
import LmsServices from "@/apiServices/LmsServices";
import moment from "@/utils/moment";
import SendEmailModal from "@/views/components/MyLeadComponents/SendEmailModal.vue";
import { email, required } from "@validations";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import infiniteScroll from "vue-infinite-scroll";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormTextarea,
    BForm,
    SendEmailModal,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BFormGroup,
    ToastificationContentVue,
    draggable,
    BTooltip,
    quillEditor,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
    user_email() {
      return this.$store.getters["user/getUserDetails"].user_email;
    },
    show_bulk_actions() {
      return this.allLeads.filter((lead) => lead.is_selected).length > 0;
    },
  },

  directives: {
    BTooltip,
  },

  data() {
    return {
      email_signature: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link", "image"],
          ],
        },
        placeholder: "",
      },
      default_order: [
        { label: "Country", position: 2 },
        { label: "Discipline", position: 3 },
        { label: "Level", position: 4 },
        { label: "Course", position: 5 },
      ],
      isTeamLeader: false,
      accessToken: null,
      accessTokenFailed: false,
      lmsApi: null,
      can_record: false,
      recording: false,
      recorder: null,
      chunks: [],

      showFilters: false,
      isLoading: false,
      passwordFieldType: "password",

      filters: {
        s_id: null,
        ss_id: null,
        state_id: null,
        city_id: null,
        search: null,
        registration_date: null,
        category_id: null,
        follow_up_date: null,
        activity_date: null,
        source_id: null,
        member_id: 5,
        m_id: null,
        c_id: null,
      },

      lc_field_values: [],

      follow_up_details: {
        stage_id: null,
        sub_stage_id: null,
        fu_remark: null,
        date: null,
        time: null,
      },

      student_form: {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      },

      showCreateStudentModal: false,
      showSignatureModal: false,

      emailDetails: {
        emailModalOpen: false,
        email: "",
      },

      bulkEmailDetails: {
        emailModalOpen: false,
        email: "",
      },

      // Table
      allLeads: [],
      selected_lead: null,
      tableColumns: [
        {
          key: "name",
          label: "Name",
          sortable: false,
          stickyColumn: true,
          show: true,
        },
        { key: "mobile", label: "Mobile", sortable: false, show: true },

        {
          key: "follow_up_remark",
          label: "Follow Up Remark",
          sortable: false,
          show: true,
        },
        { key: "sub_stage", label: "Sub Stage", sortable: false, show: true },
        { key: "email", label: "Email", sortable: false, show: true },
        // { key: "state", label: "State", sortable: false, show: true },
        { key: "city", label: "City", sortable: false, show: true },
        { key: "source", label: "Source", sortable: false, show: true },
        { key: "medium", label: "Medium", sortable: false, show: false },
        { key: "campaign", label: "Campaign", sortable: false, show: false },
        { key: "category", label: "Category", sortable: false, show: true },
        {
          key: "registration_date",
          label: "Registration Date",
          sortable: false,
          show: true,
        },
        // { key: "stage", label: "Stage", sortable: false, show: true },
        {
          key: "follow_up_date",
          label: "Follow Up Date",
          sortable: false,
          show: true,
        },

        { key: "remark", label: "Remarks", sortable: false, show: false },
      ],

      category_wise_filters: {},

      staticColumns: [],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
        items_count: 0,
      },
      busy: false,

      // Masters
      states: [],
      cities: [],
      stages: [],
      sub_stages: [],
      categories: [],
      category_fields: [],
      sources: [],
      team_members: [],
      mediums: [],
      campaigns: [],

      required,
      email,

      debouce: null,

      selected_lead_ids: [],
      selectable_lead_ids: [],
      select_all: false,
    };
  },
  directives: { infiniteScroll },

  methods: {
    getWhatsappFormattedMobile(mobile) {
      let finalNumber = [];
      mobile = mobile.split("");
      mobile.forEach((number) => {
        let n = parseInt(number);
        if (!isNaN(n)) {
          finalNumber.push(n);
        }
      });

      return finalNumber.join("");
    },

    onFilterChange() {
      this.pagination.currentPage = 1;
      if (this.debouce) {
        clearTimeout(this.debouce);
        this.debouce = null;
      }
      this.saveFilters();
      this.debouce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },

    onCategoryChange() {
      localStorage.removeItem("table_columns");
    },

    onChange() {
      this.$nextTick(() => {
        // if (this.filters.category_id) {
        this.getAllCategoryFields();
        this.getCategoryFiltersAndValues();
        // }
        this.selected_lead_ids = [];
        this.select_all = false;
        this.getSelectableLeads();
        this.getMyLeads();
      });
    },

    addNewLead() {
      this.$router.push({
        name: "Lead Form",
      });
    },

    editLead(lead_id) {
      this.$router.push({
        name: "Lead Form",
        params: { lead_id: lead_id },
      });
    },

    async getLMSAccess() {
      try {
        this.isLoading = true;
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);

        await this.getSelectableLeads();
        await this.getMyLeads();
        this.getAllStates();
        this.getAllCities();
        this.getAllLeadStages();
        this.getAllLeadSubStages();
        this.getAllMediums();
        this.getAllCampaigns();
        this.getAllLeadSources();
        this.getAllTeamMembers();
        this.getAllCategoriesByRole();
        this.getUserLMSDetails();

        if (this.filters.category_id) {
          this.getAllCategoryFields();
          this.getCategoryFiltersAndValues();
        }
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },

    async addLeaderFields() {
      if (this.isTeamLeader) {
        const find = this.tableColumns.find((i) => i.key === "assignee");
        if (!find) {
          this.tableColumns.push({
            key: "assignee",
            label: "Lead Owner",
            sortable: false,
            show: true,
          });
        }
      }
    },

    async getSelectableLeads() {
      const res = await this.lmsApi.getMyLeads({
        ...this.filters,
        category_wise_filters: this.category_wise_filters,
      });

      this.selectable_lead_ids = res.data.data.rows.map((lead) => lead.lead_id);
    },

    async saveLMSUserDetails() {
      try {
        const res = await this.lmsApi.saveUserProfile({
          user_signature_html: this.email_signature,
        });
        if (res.data.status) {
          this.showSignatureModal = false;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Signature Saved",
              icon: "Check",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed to Save Signature",
              icon: "XIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getMyLeads() {
      try {
        this.isLoading = true;

        const res = await this.lmsApi.getMyLeads({
          ...this.filters,
          limit: this.pagination.perPage,
          offset: (this.pagination.currentPage - 1) * this.pagination.perPage,
          category_wise_filters: this.category_wise_filters,
        });

        if (res.data.data.isLeader) {
          this.isTeamLeader = true;
        }

        this.addLeaderFields();

        const leadsRowArr = res.data.data.rows;
        this.pagination.items_count = res.data.data.total;
        const leads_arr = leadsRowArr.map((lead) => ({
          ...lead,
          stage_id: lead.stage_id ? lead.stage_id : 0,
          is_selected: false,
          // category_id: filters.category_id
        }));

        this.allLeads = leadsRowArr;

        const emails = leadsRowArr.filter((elem) => elem.user);
        if (this.selectable_lead_ids.length > 0) {
          this.markSelectedLeads();
        }

        this.isLoading = false;
      } catch (error) {
        console.error(`Error in getMyLeads `, error);
      }
    },

    async onScroll() {
      // try {
      //   console.log('hit')
      //   this.busy = true;
      //   this.pagination.currentPage++;
      //   await this.getMyLeads();
      //   this.busy = false;
      // } catch (error) {
      //   console.log(error);
      //   this.busy = false;
      // }
    },

    resetCreateStudentModal() {
      this.student_form = {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      };
    },

    async changeAssignee(lead) {
      const res = await this.lmsApi.saveLead(lead);

      if (res.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Lead Assigned",
            icon: "Check",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to Assign Leads",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },

    async createStudent(e) {
      e.preventDefault();
      this.$refs.addStudentForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await CounselorServices.createStudent(
              this.student_form
            );

            if (response.data.status) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Student Account Created Successfully",
                  icon: "Check",
                  variant: "success",
                },
              });

              this.showCreateStudentModal = false;
            } else {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message,
                  icon: "X",
                  variant: "failure",
                },
              });
            }
          } catch (err) {
            console.error("Error in Adding Student ", err);
          }
        }
      });
    },

    async sendStudentMail(data) {
      try {
        const res = await this.lmsApi.sendStudentMail(data);

        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Email Sent Successfully",
              icon: "Check",
              variant: "success",
            },
          });
          return;
        }
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Email",
            icon: "X",
            variant: "failure",
          },
        });
      } catch (error) {
        console.log({ error });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Email",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },

    saveFilters() {
      localStorage.removeItem("filters");
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },

    saveTableColumns() {
      localStorage.removeItem("table_columns");
      localStorage.setItem("table_columns", JSON.stringify(this.tableColumns));
    },

    resetFilters() {
      this.filters = {
        s_id: null,
        ss_id: null,
        state_id: null,
        city_id: null,
        search: null,
        registration_date: null,
        activity_date: null,
        follow_up_date: null,
        source_id: null,
        category_id: null,
        member_id: null,
      };

      this.selected_lead_ids = [];
      this.select_all = false;
      this.getAllCategoryFields();
      this.getSelectableLeads();
      this.getMyLeads();
    },

    showEmailModal(email) {
      this.emailDetails = {};
      this.emailDetails.email = email;
      this.emailDetails.emailModalOpen = true;
    },

    checkEmail(email) {
      const emailRegex = /\S+@\S+\.\S+/;
      if (emailRegex.test(email)) {
        return true;
      }
      return false;
    },

    closeEmailModal() {
      this.emailDetails.emailModalOpen = false;
      this.bulkEmailDetails.emailModalOpen = false;
      this.$forceUpdate();
    },

    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    cloneWithoutReference(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    resetTableColumns() {
      localStorage.removeItem("table_columns");
      this.tableColumns = this.cloneWithoutReference(this.staticColumns);
      this.getAllCategoryFields();
    },

    selectAllTableColumns() {
      this.tableColumns = this.tableColumns.map((tc) => ({
        ...tc,
        show: true,
      }));

      this.saveTableColumns();
    },

    // Follow Up
    showFollowUpModal(lead) {
      this.selected_lead = lead;
      this.$refs["follow-up-modal"].show();
    },

    hideFollowUpModal() {
      this.$refs["follow-up-modal"].hide();
    },

    resetFollowUpModal() {
      this.follow_up_details = {
        stage_id: null,
        sub_stage_id: null,
        fu_remark: null,
        date: null,
        time: null,
      };
    },

    onOpenCreateStudentModal(lead) {
      if (lead) {
        this.selected_lead = lead;
      }

      const currentLeadStage = this.stages.find(
        (ls) => ls.s_id === this.selected_lead.stage_id
      );
      if (currentLeadStage.name === "Completed") {
        this.showCreateStudentModal = true;

        const first_name = this.selected_lead.full_name.split(" ")[0];
        const last_name = this.selected_lead.full_name.split(" ")[1];

        this.student_form = {
          email: this.selected_lead.email,
          password: "",
          firstname: first_name || "",
          lastname: last_name || "",
          mobile: this.selected_lead.mobile,
        };
      }
    },

    async addFollowUp() {
      const { stage_id: oldStageId, sub_stage_id: oldSubStageId } =
        this.selected_lead;

      const {
        stage_id: newStageId,
        sub_stage_id: newSubStageId,
        fu_remark: newRemark,
      } = this.follow_up_details;

      const changedFollowUpDetails = {
        ...this.follow_up_details,
        stage_id: newStageId,
        sub_stage_id: newSubStageId,
        fu_remark: newRemark,
        datetime: this.follow_up_details.date
          ? moment(
              `${this.follow_up_details.date} ${this.follow_up_details.time}`
            ).toDate()
          : null,
      };

      const body = {
        ...this.selected_lead,
        ...this.follow_up_details,
        follow_up: changedFollowUpDetails,
      };

      // if (oldStageId === newStageId && oldSubStageId === newSubStageId) {
      //   return;
      // }

      const res = await this.lmsApi.saveLead(body);

      if (!res.data.status) {
        return;
      }

      this.selected_lead.stage_id = newStageId;

      this.onOpenCreateStudentModal();

      this.getMyLeads();
      this.hideFollowUpModal();
    },

    showModifyTableModal() {
      this.$refs["modify-table-modal"].show();
    },

    // Masters
    async getAllStates() {
      try {
        const res = await this.lmsApi.getAllStates();
        this.states = res.data.data;
      } catch (error) {
        console.error("Could Not Get States");
      }
    },

    async getUserLMSDetails() {
      try {
        const res = await this.lmsApi.getUserProfile();
        this.email_signature = res.data.data.user.user_signature_html;
      } catch (error) {
        console.error("Could Not Get States");
      }
    },

    async getAllCities() {
      try {
        const res = await this.lmsApi.getAllCities();
        this.cities = res.data.data;
      } catch (error) {
        console.error("Could Not Get Cities");
      }
    },

    async getAllLeadStages() {
      try {
        const res = await this.lmsApi.getAllLeadStages();
        this.stages = res.data.data;
      } catch (error) {
        console.error("Could Not Get Lead Stages");
      }
    },

    async getAllMediums() {
      try {
        const res = await this.lmsApi.getAllMediums();
        this.mediums = res.data.data;
      } catch (error) {
        console.error("Could Not Get Lead Mediums");
      }
    },

    async getAllCampaigns() {
      try {
        const res = await this.lmsApi.getAllCampaigns();
        this.campaigns = res.data.data;
      } catch (error) {
        console.error("Could Not Get Lead Campaigns");
      }
    },

    async getAllLeadSubStages() {
      try {
        const res = await this.lmsApi.getAllLeadSubStages();
        this.sub_stages = res.data.data;
      } catch (error) {
        console.error("Could Not Get Lead Sub Stages");
      }
    },

    async getAllCategoriesByRole() {
      try {
        const res = await this.lmsApi.getAllCategoriesByRole();
        this.categories = res.data.data;
      } catch (error) {
        console.error("Could Not Get Categories");
      }
    },

    async getAllCategoryFields() {
      try {
        const res = await this.lmsApi.getAllCategoryFields();

        this.category_fields = res.data.data;

        this.category_fields = this.category_fields.filter(
          (cf) => cf.lc_id === this.filters.category_id
        );

        let ls_table_columns = localStorage.getItem("table_columns");
        let pls_table_columns = [];

        if (ls_table_columns && JSON.parse(ls_table_columns)) {
          pls_table_columns = JSON.parse(ls_table_columns);
        }

        if (pls_table_columns && pls_table_columns.length) {
          this.addLeaderFields();
          let all_fields = [
            ...this.tableColumns.map((sc) => sc.key),
            ...this.category_fields.map((field) => field.lcf_id.toString()),
          ];

          let fieldsHaveChanged = false;
          for (let field of all_fields) {
            if (!pls_table_columns.find((tc) => tc.key === field)) {
              fieldsHaveChanged = true;
              break;
            }
          }

          if (!fieldsHaveChanged) {
            for (let tc of pls_table_columns) {
              if (!all_fields.find((field) => field === tc.key)) {
                fieldsHaveChanged = true;
                break;
              }
            }
          }

          if (fieldsHaveChanged) {
            localStorage.removeItem("table_columns");
            this.tableColumns = this.cloneWithoutReference(this.staticColumns);
          } else {
            this.tableColumns = pls_table_columns;
          }
        }

        let newColumns;

        if (this.category_id === 1) {
          newColumns = this.tableColumns.filter((tc) =>
            this.cloneWithoutReference(this.staticColumns).find(
              (sc) =>
                sc.key === tc.key ||
                this.default_order.find((d) => d.label === tc.label)
            )
          );
        } else {
          newColumns = this.tableColumns.filter((tc) =>
            this.cloneWithoutReference(this.staticColumns).find(
              (sc) => sc.key === tc.key
            )
          );
        }

        if (this.category_fields.length > 0) {
          newColumns = [
            ...newColumns,
            ...this.category_fields.map((field) => {
              return {
                key: field.lcf_id.toString(),
                label: field.name,
                sortable: false,
                show: this.tableColumns.find(
                  (tc) => tc.key === field.lcf_id.toString() && tc.show
                )
                  ? true
                  : false,
              };
            }),
          ];
        }

        let arrangedNewColumns = newColumns;

        if (!ls_table_columns) {
          arrangedNewColumns = newColumns.filter(
            (nc) => !this.default_order.find((d) => nc.label === d.label)
          );

          for (let col of this.default_order) {
            const requiredColumn = newColumns.find(
              (nc) => nc.label === col.label
            );
            if (requiredColumn)
              arrangedNewColumns.splice(col.position, 0, {
                ...requiredColumn,
                show: true,
              });
          }
        }

        this.tableColumns = arrangedNewColumns;

        this.addLeaderFields();
      } catch (error) {
        console.error("Could Not Get Category Fields ", error);
      }
    },

    async getAllLeadSources() {
      try {
        const res = await this.lmsApi.getAllLeadSources();
        this.sources = res.data.data;
      } catch (error) {
        console.error("Could Not Get Lead Sources");
      }
    },

    async getAllTeamMembers() {
      try {
        const res = await this.lmsApi.getAllTeamMembers();
        this.team_members = res.data.data;
      } catch (error) {
        console.error("Could Not Get Team Members");
      }
    },

    async getCategoryFiltersAndValues() {
      try {
        if (!this.filters.category_id) return;

        const res = await this.lmsApi.getCategoryFiltersAndValues({
          lc_id: this.filters.category_id,
        });
        this.lc_field_values = res.data.data;

        for (let lcfv of this.lc_field_values) {
          this.category_wise_filters = {
            ...this.category_wise_filters,
            [lcfv.lcf_id]: "",
          };
        }
      } catch (error) {
        console.log({ error });
        console.error("Could Not Get Category Filters");
      }
    },

    // Helpers
    getSlashSepearatedValues(...values) {
      let arr = [];
      for (let value of values) {
        if (value) {
          arr.push(value);
        }
      }

      return arr.join(" / ");
    },

    getLeadStagesWithUntouched(stage_id) {
      if (stage_id || this.stages.length === 0) return [];

      let leadStages = this.stages;
      if (!stage_id) {
        leadStages = [{ s_id: 0, name: "Untouched" }, ...leadStages];
      }

      return leadStages;
    },

    isFollowUpDateRequired() {
      if (!this.follow_up_details.stage_id) return true;
      const currentFollowUpStage = this.stages.find(
        (s) => s.s_id === this.follow_up_details.stage_id
      );
      return currentFollowUpStage.is_final ? false : true;
    },

    // Bulk Actions

    selectOrDeselectAll() {
      if (this.select_all) {
        this.selected_lead_ids = this.selectable_lead_ids;
        this.allLeads = this.allLeads.map((lead) => ({
          ...lead,
          is_selected: true,
        }));
      } else {
        this.selected_lead_ids = [];
        this.allLeads = this.allLeads.map((lead) => ({
          ...lead,
          is_selected: false,
        }));
      }
    },

    selectOrDeselectSingle(lead) {
      if (lead.is_selected) {
        this.selected_lead_ids.push(lead.lead_id);
        this.allLeads = this.allLeads.map((l) =>
          lead.lead_id === l.lead_id ? { ...l, is_selected: true } : l
        );

        if (this.selectable_lead_ids.length === this.selected_lead_ids.length) {
          this.select_all = true;
        }
      } else {
        this.select_all = false;
        this.selected_lead_ids = this.selected_lead_ids.filter(
          (l) => l !== lead.lead_id
        );
        this.allLeads = this.allLeads.map((l) =>
          lead.lead_id === l.lead_id ? { ...l, is_selected: false } : l
        );
      }
    },

    markSelectedLeads() {
      this.allLeads = this.allLeads.map((l) =>
        this.selected_lead_ids.includes(l.lead_id)
          ? { ...l, is_selected: true }
          : l
      );
    },

    async showBulkEmailModal() {
      const res = await this.lmsApi.getEmailByLead({
        lead_ids: Array.from(new Set(this.selected_lead_ids)),
      });
      if (!res.data.status) {
        toast.error("An Unexpected error has occured");
        return;
      }
      this.bulkEmailDetails.email = Array.from(
        new Set(res.data.data.map((l) => l.email))
      )
        .filter((e) => this.checkEmail(e))
        .join("\n");
      this.bulkEmailDetails.emailModalOpen = true;
    },

    async sendBulkMail(data) {
      try {
        const res = await this.lmsApi.sendStudentMail({
          ...data,
          lead_ids: this.selected_lead_ids,
        });
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Emails Sent Successfully",
              icon: "Check",
              variant: "success",
            },
          });
          return;
        }
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Emails",
            icon: "X",
            variant: "failure",
          },
        });
      } catch (error) {
        console.log({ error });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Emails",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },

    moment,

    SetUpAudio() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(this.SetUpStream)
          .catch(function (err) {
            console.log(err.name + ": " + err.message);
          });
      }
    },
    SetUpStream(stream) {
      this.recorder = new MediaRecorder(stream);

      // Use an arrow function to preserve 'this'
      this.recorder.ondataavailable = (e) => {
        this.chunks.push(e.data);
      };

      this.recorder.onstop = async () => {
        const blob = new Blob(this.chunks, { type: "audio/mp3; codecs=opus" });
        const audioURL = window.URL.createObjectURL(blob);
        // const audio = document.querySelector(".playback");
        // audio.src = audioURL;
        // audio.controls = true;
        await this.uploadAudio(blob);
        this.chunks = [];
      };

      this.can_record = true;
    },

    ToggleMic() {
      if (!this.can_record) {
        console.error("Recorder not ready. Please allow microphone access.");
        return;
      }
      this.recording = !this.recording;
      if (this.recording) {
        this.recorder.start();
        this.follow_up_details.fu_remark = "Speak...";
      } else {
        this.recorder.stop();
        this.follow_up_details.fu_remark = "Generating ...";
      }
    },
    async uploadAudio(blob) {
      try {
        const formData = new FormData();
        formData.append("audioFile", blob, "recording.mp3"); // Correct extension

        const response = await CommonServices.sendAudio(formData);

        if (response.data.status) {
          // console.log("Audio file uploaded successfully:", response.data);
          this.follow_up_details.fu_remark = response.data.data;
        } else {
          console.error("Failed to upload audio file:", response.statusText);
        }
      } catch (error) {
        console.error("Error uploading audio file:", error);
      }
    },
  },

  beforeMount() {
    let filters = localStorage.getItem("filters");
    if (filters && JSON.parse(filters)) {
      this.filters = JSON.parse(filters);
    }

    this.staticColumns = this.cloneWithoutReference(this.tableColumns);

    this.getLMSAccess();
    this.SetUpAudio();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
<style>
[dir="ltr"] .dropdown-menu[x-placement="right-start"] {
  margin-left: 3rem;
}

.ql-editor {
  height: 300px;
}

.mydiv {
  border-bottom: 1px solid blue;
  padding-bottom: 10px;
}
/* .mic {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
} */
.mic-container {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
textarea.form-control {
  padding: 0.8rem 4rem 1rem 0.8rem !important;
}

.mic-container .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12),
    0 3px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative; /* Required for pseudo-elements */
}

.mic-container .circle:before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.2;
  background: #b2b1b1;
  z-index: -1;
  position: absolute;
  transform: scale(1); /* Default state */
  transition: transform 0.3s ease; /* Smooth scaling */
}

.mic-container .circle.active {
  background: #ff0000;
}

.mic-container .circle.active:before {
  background: rgb(51, 49, 49);
  animation: bounce 0.8s ease-in-out infinite; /* Apply bounce animation */
}

.mic-container .circle .mic-icon {
  color: #b2b1b1;
  transition: color 0.3s ease;
}

.mic-container .circle.active .mic-icon {
  color: #ffffff;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
/* From Uiverse.io by Yaseen549 */
</style>
